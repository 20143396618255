<template>
  <div id="ai-services_aiu_image-clustering">
    <service-template
      :run="run"
      :run-disabled="currentExample === null && uploadedImage === null"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/CST.svg')"
      type="image"
      service="aiu"
    >
      <template v-slot:header>
        {{ $t("services.imageClustering.header") }}
      </template>
      <template v-slot:left>
        <carousel
          :step="1"
          :title="$t(`services.carousel.zipTitle`)"
        >
          <div
            v-for="(value, key, index) in examples"
            :slot="`slide-${index}`"
            :key="index"
            class="example-thumbnail"
            :class="currentExample == key && 'selected'"
            @click="onExampleClick(key)"
          >
            <img
              :src="currentExample == key ? svg.ActiveZip : svg.InActiveZip"
              alt="Zip file"
              class="icon"
            >
            <span class="title font-600 truncate">{{
              $t("services.example", [index + 1])
            }}</span>
          </div>
        </carousel>
        <div
          v-if="currentExample"
          class="cluster-example"
        >
          <el-image
            v-for="(link, index) in exampleImages"
            :key="index"
            :src="link"
            :preview-src-list="[link]"
            :z-index="9001"
            class="cluster-img"
          />
        </div>
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.imageClustering.header')"
          :loading="loading"
        >
          <div
            v-for="(cluster, index) in result"
            :key="index"
            class="cluster-container"
          >
            <h6 class="cluster-title">
              {{ $t("services.cluster", [index + 1]) }}
            </h6>
            <el-image
              v-for="(link, clsIndex) in cluster"
              :key="clsIndex"
              :src="link"
              :preview-src-list="[link]"
              :z-index="9001"
              class="cluster-img"
            />
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import InActiveZip from '@/assets/images/icons/zip.svg';
import ActiveZip from '@/assets/images/icons/zip-active.svg';

export default {
  name: 'ImageClassification',
  components: {
    Carousel,
    GeneratedResult,
    ServiceTemplate,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
    uploadedImage: null,
    examples: [],
  }),
  computed: {
    svg: () => ({
      InActiveZip,
      ActiveZip,
    }),
    ...mapGetters(['isAuthenticated']),
    exampleImages() {
      return this.examples[this.currentExample].examples.map(
        (src) => `${this.$consts.publicPath}/examples/AIU/CST/${this.currentExample}/${src}`,
      );
    },
  },
  async created() {
    ({ default: this.examples } = await import(
      '@/../public/examples/AIU/CST/samples.json'
    ));
    this.currentExample = Object.keys(this.examples)[0];
  },
  methods: {
    async run() {
      const { results } = this.examples[this.currentExample];
      const cluster = [];

      Object.entries(results).forEach(([link, clsType]) => {
        cluster[clsType] = [...(cluster[clsType] || []), this.getExampleLink(link)];
      });

      return cluster;
    },
    getExampleLink(src) {
      return `${this.$consts.publicPath}/examples/AIU/CST/${this.currentExample}/${src}`;
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(key) {
      if (this.loading) {
        return;
      }

      this.currentExample = key;
      this.uploadedImage = null;
      this.result = null;
    },
    onUploadRemove() {
      this.result = null;
      this.uploadedImage = null;
      this.currentExample = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
  },
};
</script>

<style lang='scss' scoped>
#ai-services_aiu_image-clustering {
  background: $background-secondary;

  .example-thumbnail {
    display: flex;
    align-items: center;
    height: 0.46rem;
    padding: 0 0.06rem;
    border-radius: 4px;
    border: 1px solid #4791ff;
    color: #4791ff;
    background: #e1eaff;
    cursor: pointer;
    width: 100%;

    &.selected {
      background-color: #4791ff;
      color: #fff;
    }

    .icon {
      width: 0.18rem;
      height: 0.18rem;
      margin-right: 0.08rem;
    }

    .title {
      font-size: 0.14rem;
    }
  }

  .cluster-example {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    background: #f3f6ff;
    border: 1px solid #c0ccda;
    padding: 0.04rem;
    border-radius: 4px;
    min-height: 2.5rem;

    .cluster-img {
      align-self: baseline;
    }
  }

  .cluster-img {
    max-width: 0.75rem;
    margin: 0.02rem;
    border-radius: 2px;
  }

  .cluster-container {
    &:not(:last-child) {
      margin-bottom: .2rem;
    }

    .cluster-title {
      font-size: 0.14rem;
      font-weight: 600;
      margin-bottom: .03rem;
      text-transform: capitalize;
    }
  }

  ::v-deep {
    .wrapper.box .swiper-slide {
      width: 1.3rem;
    }
  }
}
</style>
